var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-main', [_c('v-row', {
    staticClass: "h-100",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "center",
    attrs: {
      "no-guttore": "",
      "cols": "12",
      "lg": "5"
    }
  }, [_c('Base')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }