<template>
  <v-main>
    <v-row no-gutters class="h-100">
      <v-col no-guttore cols="12" lg="5" class="center">
        <Base />
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import Base from './base';

export default {
  components: {
    Base,
  },
};
</script>
