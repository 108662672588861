<template>
  <v-card flat :outlined="$attrs.outlined">
    <v-row justify="center">
      <v-col cols="12" sm="10">
        <v-form ref="loginForm" v-model="form.login" lazy-validation @keyup.native.enter="login()">
          <v-card-title>Login </v-card-title>

          <v-card-text>
            <vx-input
              v-model="email"
              type="email"
              name="name"
              label="Email"
              :rules="[v => !!v || 'Email is required']"
            />

            <vx-input
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="name"
              label="Password"
              :rules="[v => !!v || 'Password is required']"
              @click:append="show1 = !show1"
            />

            <vx-btn block color="primary" :disabled="!form.login" :loading="loading.login" @click="$validate(login)">
              Login
            </vx-btn>
          </v-card-text>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { AuthService, UserService } from '@tutti/services';

export default {
  data: () => ({
    show1: false,
    form: {
      login: false,
      register: false,
    },
    loading: {
      login: false,
      register: false,
    },
    email: '',
    password: '',
  }),
  methods: {
    async login() {
      this.loading.login = true;

      if (this.$refs.loginForm.validate()) {
        const response = await AuthService.login({
          admin: true,
          source: 'custom',
          login: this.email,
          password: this.password,
        });

        this.$login(response.data);

        if (response) {
          UserService.get().then(res => {
            if (res) {
              this.$login({ user: res.data });
            }
          });

          this.$emit('close');
        }
      }
      this.loading.login = false;
    },
  },
};
</script>
