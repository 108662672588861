var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "outlined": _vm.$attrs.outlined
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "10"
    }
  }, [_c('v-form', {
    ref: "loginForm",
    attrs: {
      "lazy-validation": ""
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.login();
      }
    },
    model: {
      value: _vm.form.login,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "login", $$v);
      },
      expression: "form.login"
    }
  }, [_c('v-card-title', [_vm._v("Login ")]), _c('v-card-text', [_c('vx-input', {
    attrs: {
      "type": "email",
      "name": "name",
      "label": "Email",
      "rules": [function (v) {
        return !!v || 'Email is required';
      }]
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('vx-input', {
    attrs: {
      "append-icon": _vm.show1 ? 'mdi-eye' : 'mdi-eye-off',
      "type": _vm.show1 ? 'text' : 'password',
      "name": "name",
      "label": "Password",
      "rules": [function (v) {
        return !!v || 'Password is required';
      }]
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.show1 = !_vm.show1;
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('vx-btn', {
    attrs: {
      "block": "",
      "color": "primary",
      "disabled": !_vm.form.login,
      "loading": _vm.loading.login
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.login);
      }
    }
  }, [_vm._v(" Login ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }